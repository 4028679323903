import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/valparaiso.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/mountain-selfie.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Hey there, my name is Spencer. I have been a software developer for
            nearly a decade now, living in beautiful British Columbia. I
            specialize in Nodejs Backend development and have recently been
            working in more Full Stack roles happy to help my team out wherever
            I can.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            I love looking into new technologies and spinning up fun new
            projects. These projects have included applications such as;
            <ul>
              <li>
                {' '}
                a location tagging application to help centralize and organize
                Help Wanted signs throughout your city.{' '}
              </li>
              <li>
                {' '}
                Recipe tracking application for finding meals and planning out
                your week.{' '}
              </li>
            </ul>
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            I live with my family in beautiful Squamish British Columbia. We
            love to spend as much time outdoors as we can. In the winter this
            involves Backcountry Skiing, Snowboarding, Ice Skating. In the
            summers we can be found Mountain Biking, Hiking, and running.
            <br />
            In the past couple years I have picked up a passion for ultra
            marathons. I love pushing myself further and seeing how far we can
            get under our own power.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="POST" name="contact" data-netlify="true" action="/">
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <button type="submit" className="special">
                  Send Message
                </button>
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <form name="contact" netlify netlify-honeypot="bot-field" hidden>
            <input type="text" name="name" />
            <input type="email" name="email" />
            <textarea name="message"></textarea>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/spencerhutch"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/spencerhutchin/"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/Spencerhutch"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            {/* <li>
              <a href="https://www.instagram.com/spencerhutchin/" className="icon fa-strava">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="strava" class="svg-inline--fa fa-strava fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M158.4 0L7 292h89.2l62.2-116.1L220.1 292h88.5zm150.2 292l-43.9 88.2-44.6-88.2h-67.6l112.2 220 111.5-220z"></path></svg>
                <span className="label">Strava</span>
              </a>
            </li> */}
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
